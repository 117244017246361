import React, {Fragment, useState} from 'react';
import {Typography, Row, Col, Card} from 'antd';
import styled from '@emotion/styled';
import italy from './images/italy.jpg';

import {WorldMap} from 'grommet';

const {Title, Text, Paragraph} = Typography;
const {Meta} = Card;

const ImageWrapper = styled(Paragraph)`
  position: relative;
`;

const Capture = styled(Text)`
  position: absolute;
  left: 5px;
  bottom: 5px;
`;

export default () => {
  const [city, setCity] = useState('');

  const onHoverHandler = (hovered: boolean, city: string) => {
    if (hovered) {
      setCity(city);
    } else {
      setCity('');
    }
  };

  return (
    <Fragment>
      <Card bordered={false} style={{background: 'none'}}>
        <Meta
          title={
            <Row type="flex" justify="space-between">
              <Col>
                <Title level={2}>Map of places I liked.</Title>
              </Col>
              <Col>
                <Text mark>{city}</Text>
              </Col>
            </Row>
          }
          description={
            <ImageWrapper>
              <img alt="taken in Manarola, Italy." src={italy} style={{width: '100%'}} />
              <Capture strong mark>
                Manarola, Italy.
              </Capture>
            </ImageWrapper>
          }
        />

        <WorldMap
          style={{marginTop: `1em`}}
          color="#cccfd6"
          places={[
            {
              name: 'Tokyo',
              location: [35.689487, 139.691711],
              color: '#1500ff',
              onClick: name => {},
              onHover: hovered => onHoverHandler(hovered, 'Tokyo'),
            },
            {
              name: 'Hong Kong',
              location: [22.396427, 114.109497],
              color: '#1500ff',
              onClick: name => {},
              onHover: hovered => onHoverHandler(hovered, 'Hong Kong'),
            },
            {
              name: 'El Nido',
              location: [11.209709, 119.462257],
              color: '#1500ff',
              onClick: name => {},
              onHover: hovered => onHoverHandler(hovered, 'El Nido'),
            },
            {
              name: 'Omsk',
              location: [54.98848, 73.324234],
              color: '#1500ff',
              onClick: name => {},
              onHover: hovered => onHoverHandler(hovered, 'Omsk'),
            },
            {
              name: 'Moscow',
              location: [55.755825, 37.617298],
              color: '#1500ff',
              onHover: hovered => onHoverHandler(hovered, 'Moscow'),
            },
            {
              name: 'Rome',
              location: [41.902782, 12.496365],
              color: '#1500ff',
              onHover: hovered => onHoverHandler(hovered, 'Rome'),
            },
            {
              name: 'Paris',
              location: [48.856613, 2.352222],
              color: '#1500ff',
              onHover: hovered => onHoverHandler(hovered, 'Paris'),
            },
            {
              name: 'London',
              location: [51.507351, -0.127758],
              color: '#1500ff',
              onHover: hovered => onHoverHandler(hovered, 'London'),
            },
            {
              name: 'New York',
              location: [40.712776, -74.005974],
              color: '#1500ff',
              onHover: hovered => onHoverHandler(hovered, 'New York'),
            },
            {
              name: 'Los Angeles',
              location: [34.052235, -118.243683],
              color: '#1500ff',
              onHover: hovered => onHoverHandler(hovered, 'Los Angeles'),
            },
            {
              name: 'San Francisco',
              location: [37.774929, -122.419418],
              color: '#1500ff',
              onHover: hovered => onHoverHandler(hovered, 'San Francisco'),
            },
          ]}
        />
      </Card>
    </Fragment>
  );
};
