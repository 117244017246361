import React, {Fragment} from 'react';
import styled from '@emotion/styled';
import {Card, Typography} from 'antd';

import london from './images/london.jpg';
import soho from './images/soho.jpg';

const {Paragraph, Text, Title} = Typography;
const {Meta} = Card;

const MusicAlbum = styled.iframe`
  border: 0;
  width: 100%;
  height: 450px;
`;

const ImageWrapper = styled(Paragraph)`
  position: relative;
`;

const Capture = styled(Text)`
  position: absolute;
  left: 5px;
  bottom: 5px;
`;

export default () => (
  <Fragment>
    <Card bordered={false} style={{background: 'none'}}>
      <Meta
        title={<Title level={2}>Nicky Butter - Loadstar.</Title>}
        description={
          <Fragment>
            <ImageWrapper>
              <img alt="taken in London, England." src={london} style={{width: '100%'}} />
              <Capture strong mark>
                London, England.
              </Capture>
            </ImageWrapper>
            <Paragraph>
              LIVE FREE OR DIE - my motto for my second EP. I promised myself that when I hit it I
              would never compromise myself again for any music genre. So as I start this new album
              cycle, Im the most excited I’ve ever been to create, because this time it’s going to
              be an outpouring of my purest art and authentic self, both musically and visually.
            </Paragraph>
            <Paragraph>
              <MusicAlbum src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/76681702&color=%231500ff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" />
            </Paragraph>
            <Paragraph>
              As a young music producer, I fell victim to people in the biz who said things like,
              "you should be darker", or "make happier songs", or of course my favorite: "no one is
              going to listen to it." So, I’m too tired of that bullshit now, and fortunately just
              in time for my 26th birthday, I got the blessing to do whatever the hell I want :)
            </Paragraph>
          </Fragment>
        }
      />
    </Card>
    <Card bordered={false} style={{background: 'none'}}>
      <Meta
        title={<Title level={2}>Nicky Butter - Heavy Snow City.</Title>}
        description={
          <Fragment>
            <ImageWrapper>
              <img alt="taken in Shoreditch x London, England" src={soho} style={{width: '100%'}} />
              <Capture strong mark>
                Shoreditch x London, England.
              </Capture>
            </ImageWrapper>
            <Paragraph>
              Yes, I have plans to release the second EP soon. I hope you all were able to hear the
              first one. I currently have no official release date, but you can def speed up the
              process by "liking" my tracks on Soundcloud! :)
            </Paragraph>
            <Paragraph>
              <MusicAlbum src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/19566698&color=%231500ff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true" />
            </Paragraph>
            <Paragraph>
              I&#39;m also proud to announce that{' '}
              <Text strong>Aaron Smith x Nicky Butter x KRONO - Dancing</Text> has reached more than
              1.5K plays. Before I drop a new EP, I want to create as much awareness of the first
              one on a global level. That&#39;s where I need your help. To accomplish this please
              share, like and listen to these tracks below.
            </Paragraph>
            <Paragraph>Thank you.</Paragraph>
          </Fragment>
        }
      />
    </Card>
  </Fragment>
);
