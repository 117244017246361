import React, {useState} from 'react';
import styled from '@emotion/styled';

import {Row, Col, Card, Typography} from 'antd';
import avatar1 from './avatar2.jpg';
import avatar2 from './avatar2.jpg';
import avatar7 from './avatar7.jpg';

const {Paragraph, Title} = Typography;
const {Meta} = Card;

const ImageWrapper = styled(Paragraph)`
  position: relative;
`;

export default () => {
  const [avatar, setAvatar] = useState(avatar2);

  const onClickHandler = () => setAvatar(avatar === avatar2 ? avatar1 : avatar2);

  return (
    <Row type="flex">
      <Col xs={24}>
        <Card bordered={false} onClick={onClickHandler}>
          <Meta
            title={<Title level={2}>Combining the fields of art, craft and design.</Title>}
            description={
              <ImageWrapper>
                <img style={{width: '100%'}} alt="Nikita's cover" src={avatar7} />
              </ImageWrapper>
            }
          />
        </Card>
      </Col>
    </Row>
  );
};
