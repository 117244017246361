import React from 'react';
import {Global, css} from '@emotion/core';
import {ConfigProvider, Layout, Row, Col, Typography, Icon} from 'antd';
import styled from '@emotion/styled';
import {Redirect, Route, Switch, BrowserRouter, NavLink} from 'react-router-dom';

import 'antd/dist/antd.css';
import Homepage from './homepage';
import Music from './music';
import Places from './places';
// import Clothing from './clothing';

const {Content, Header, Footer} = Layout;
const {Text} = Typography;
// Global stylying option in case we need it
const Root = css`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  #root {
  }
`;

const StyledLayout = styled(Layout)`
  background: none;
  // height: calc(100vh);
  @media (min-width: 768px) {
    // padding: 60px;
    max-width: 900px;
    margin: 0 auto;
  }
`;

const StyledHeader = styled(Header)`
  background: none;
  padding: 0 24px;
  // position: fixed;
  z-index: 1;
  width: 100%;
`;

const StyledContent = styled(Content)`
  @media (min-width: 768px) {
    // padding: 60px;
    // max-width: 900px;
    // margin: 0 auto;
  }
  width: 100%;
`;

const StyledFooter = styled(Footer)`
  background: none;
  padding: 0 24px;
  height: 64px;
  line-height: 64px;
`;

const StyledA = styled.a`
  color: #1500ff;
  &:hover {
    color: #1500ff;
  }
`;

const activeStyle = {
  color: 'red',
};

const StyledNavLink = styled(NavLink)`
  &.active .ant-typography {
    color: #000000d9;
  }
`;

const NOW = new Date();

const App: React.FC = () => {
  const currentYear = NOW.getFullYear();
  return (
    <BrowserRouter>
      <Global styles={Root} />
      <ConfigProvider>
        <StyledLayout>
          <StyledHeader>
            <Row type="flex" gutter={16} justify="space-between">
              <Col>
                <StyledNavLink activeStyle={activeStyle} to="">
                  <Text strong style={{color: '#000000d9'}}>
                    untitled
                  </Text>{' '}
                  <Text style={{color: '#00000040', fontWeight: 600}}>project.</Text>
                </StyledNavLink>
              </Col>
              <Row type="flex" gutter={16}>
                <Col>
                  <a href="https://blog.nikonenik.com">
                    <Text strong type="secondary">
                      blog.
                    </Text>
                  </a>
                </Col>
                <Col>
                  <StyledNavLink to="/music">
                    <Text strong type="secondary">
                      music.
                    </Text>
                  </StyledNavLink>
                </Col>
                <Col>
                  <StyledNavLink to="/lifestyle">
                    <Text strong type="secondary">
                      lifestyle.
                    </Text>
                  </StyledNavLink>
                </Col>
              </Row>
            </Row>
          </StyledHeader>
          <StyledContent>
            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route path="/music" component={Music} />
              <Route path="/lifestyle" component={Places} />
              <Redirect to="/" />
            </Switch>
          </StyledContent>
          <StyledFooter>
            <Row type="flex" justify="space-between">
              <Row type="flex" gutter={16}>
                <Col>
                  <StyledA href="https://www.instagram.com/nikima">
                    <Icon style={{fontSize: 16}} type="instagram" />
                  </StyledA>
                </Col>
                <Col>
                  <StyledA href="https://twitter.com/nickybutterLA">
                    <Icon style={{fontSize: 16}} type="twitter" />
                  </StyledA>
                </Col>
                <Col>
                  <StyledA href="mailto:nikonenik@gmail.com">
                    <Icon style={{fontSize: 16}} type="mail" />
                  </StyledA>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Text strong mark>
                    © {currentYear} Nikita Maslennikov.
                  </Text>
                </Col>
              </Row>
            </Row>
          </StyledFooter>
        </StyledLayout>
      </ConfigProvider>
    </BrowserRouter>
  );
};
export default App;
